import {createWidgetPreloader} from '../source/preloader'

///////////////////////////////////////////////////////////////////////////////

let widgetPreloader = createWidgetPreloader({
  resolvers: {
    initialConfig: async () => {
      return {
        customConfig: require('../config/config.**.yml'),
      }
    },
    preloaderCSS: () => import('./styles/preloader'),
    widgetCSS: () => import('./styles/widget'),
  },
})

///////////////////////////////////////////////////////////////////////////////

window['rnw'] = window['rnw'] || {}
window['rnw']['tamaro'] = widgetPreloader
